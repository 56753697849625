import React, { /* Component, */ useEffect, useState } from "react";
import DatePicker from "react-datepicker";
// import axios from "axios";
import moment, { max } from "moment";
import 'react-datepicker/dist/react-datepicker.css'

import classes from "./Dashboard.module.scss";

import { Row, Col, Select, Divider, Table, Button, Tabs, Tag } from "antd";
import { Link } from "react-router-dom";

import { Pie, DualAxes } from "@ant-design/charts";
import { G2 } from "@ant-design/plots";
import * as routes from "./../../constants/routes";
import {
  ADMIN_GET_PLANS_AMOUNTS,
  ADMIN_GET_CSV_OVERVIEW,
} from "./../../constants/api";
import Spinner from './../../Components/Spinner';

import AmountCards from "./../../Components/AmountCards";
import useFetch from "./../../hooks/useFetch";
import _CONFIG_PIE_PROFESSION from "./../../config_charts/config_pie_professions";
import _CONFIG_LINE_BY_DAY from "./../../config_charts/config_line_by_day";
// import _CONFIG_LINE_BY_MONTH from "./config_charts/config_line_by_month";
import { Auth } from "aws-amplify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSpinner,
  faDownload,
  faExpand,
  faCheckCircle,
  faCircle,
  faArrowAltCircleLeft,
  faRetweet,
  faTable,
  faIdCardAlt,
  faAdjust
} from "@fortawesome/fontawesome-free-solid";
// import { faSearch, faSpinner, faDownload ,faWindowMaximize, faWindowMinimize} from '@fortawesome/fontawesome-free-regular'

// const NEXT_URL = 'https://mcqj5lejdg.execute-api.us-east-1.amazonaws.com/v1'
//const NEXT_URL = 'https://k9357p16ih.execute-api.us-east-1.amazonaws.com/next-dev-2/'
export default function Dashboard() {
  const { TabPane } = Tabs;

  const map_application_name = {
    afinando_v3: "Afinando o Cérebro",
    afinando_plus: "Afinando Plus",
    users: "Usuários",
    idiomas: "Idiomas",
    "parent": "Usuários Com Filhos",
    "individual": "Usuários Sem Filhos",
    "patient": "Usuários Com Pais",
    "grand": "Usuários Com Filhos e Com Pais",
    "probrain_review": "Dados por Período"
  };


  const map_color_tag = {
    "Único": 'geekblue',
    "Recorrente": 'green',
    "Legado": "volcano"
  }

  const [professions, setProfessions] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [fixeSizeCreateByDay, setFixeSizeCreateByDay] = useState(false);
  const [legendCreatByDay, setLegendCreatByDay] = useState([]);
  const [dateEnd, setDateEnd] = useState("");
  const [typeShowNumbers, setTypeShowNumbers] = useState(0);
  const [typePieChart, setTypePieChart] = useState(0);
  const [plansAmmount, setPlansAmmount] = useState({});
  const [nextChargeUsers, setNextChargeUsers] = useState({});
  const [tablePlans, setTablePlans] = useState([]);
  const [toogleLegends, setToogleLegends] = useState(true);
  const [focusState, setFocusState] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [graphPieUpdate, setgraphPieUpdate] = useState("");
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [CONFIG_PIE_PROFESSION, setCONFIG_PIE_PROFESSION] = useState(
    _CONFIG_PIE_PROFESSION
  );
  const [CONFIG_LINE_BY_DAY, setCONFIG_LINE_BY_DAY] =
    useState(_CONFIG_LINE_BY_DAY);
  // const [CONFIG_LINE_BY_MONTH, setCONFIG_LINE_BY_MONTH] = useState(_CONFIG_LINE_BY_MONTH)

  const {
    response: response_ammounts,
    error: response_error_ammounts,
    fetch: request_ammounts,
  } = useFetch({
    autoInit: false,
  });

  const { fetch: download_csv, response: response_download_csv } = useFetch({
    autoInit: false,
  });
  const tableCollums = [{
    title: 'Produto',
    dataIndex: 'product',
    key: 'product'
  }, {
    title: 'Plano',
    dataIndex: 'plan',
    key: 'plan'
  }, {
    title: 'Tipo do Produto',
    dataIndex: 'type',
    key: 'type'
  }, {
    title: 'Tag do Produto',
    dataIndex: 'tag',
    key: 'tag'
  }, {
    title: 'Quantidade',
    dataIndex: 'amt',
    key: 'amt'
  }, {
    title: 'Quantidade por pessoa',
    dataIndex: 'amt_sub',
    key: 'amt_sub'
  }]

  useEffect(() => {
    const tmpConf = { ...CONFIG_LINE_BY_DAY };
    tmpConf['legend']['selected'] = {}
    if (response_ammounts) {
      Object.keys(legendCreatByDay).forEach(legend => {
        tmpConf['legend']['selected'][legend] = toogleLegends
      })
      // response_ammounts.created_by_day.forEach((typeGraph) => {
      //   Object.keys(typeGraph).forEach((item) => {
      //     tmpConf['legend']['selected'][typeGraph[item]['name']] = toogleLegends
      //   })
      // })
    }
    setCONFIG_LINE_BY_DAY(tmpConf);
  }, [toogleLegends])

  useEffect(() => {
    if (response_download_csv) {
      setLoadingCSV(false);
      const link = document.createElement("a");
      link.href = response_download_csv['url'];
      link.click();
    }
  }, [response_download_csv]);

  useEffect(() => {
    if (!response_ammounts)
      request_ammounts({ url: ADMIN_GET_PLANS_AMOUNTS(), method: "GET", Headers: { "Access-Control-Allow-Origin": "*", Authorization: Auth.user?.signInUserSession?.idToken?.jwtToken } });
  }, []);
  // useEffect(() => {
  //     if (focusState==2){
  //         setCONFIG_LINE_BY_DAY({...CONFIG_LINE_BY_DAY,'legend':{'position':'left','maxRow':10} })
  //     }else{
  //         setCONFIG_LINE_BY_DAY({...CONFIG_LINE_BY_DAY,'legend':{'position':'top-left','maxRow':10} })
  //     }
  // }, [focusState])
  const updateOnClick = (_profession) => {
    setgraphPieUpdate(_profession);
  };

  useEffect(() => {
    if (graphPieUpdate) {
      if (
        graphPieUpdate in professions["per_profession"] ||
        (graphPieUpdate == "vazio" &&
          "Vazio" in professions["per_profession"])
      )
        setCONFIG_PIE_PROFESSION({
          ...CONFIG_PIE_PROFESSION,
          data: convert_data_to_graph_profession(
            professions["per_profession"][
            graphPieUpdate == "vazio" ? "Vazio" : graphPieUpdate
            ]
          ),
        });
      else
        setCONFIG_PIE_PROFESSION({
          ...CONFIG_PIE_PROFESSION,
          data: convert_data_to_graph_profession(
            (professions['per_plan'][graphPieUpdate] === undefined ? professions['per_profession'][graphPieUpdate] : professions['per_plan'][graphPieUpdate])
          ),
        });
    } else if (Object.keys(professions).length > 0) {
      setCONFIG_PIE_PROFESSION({
        ...CONFIG_PIE_PROFESSION,
        data: convert_data_to_graph_profession(
          professions["geral"]
        ),
      });
    }
  }, [graphPieUpdate]);

  const updateOverviewAmmount = () => {
    if (response_ammounts && "plans" in response_ammounts) {
      let _plans_list = {}
      let initialMap = response_ammounts.plans.reduce((acc, plan) => {
        if (plan["parent_product"] == 0) {
          if (!Object.hasOwn(acc, plan["application"])) {
            acc[plan["application"]] = {
              base: [],
              addon: {},
            };
          }
        }
        return acc
      }, {})
      let map_parent_id = {}
      let map_base_by_id = response_ammounts.plans.reduce((acc, plan) => {
        if (plan["parent_product"] == 0) {
          acc[plan.product_id] = plan.application
        }
        if (Object.hasOwn(map_parent_id, plan["parent_product"])) {
          map_parent_id[plan['parent_product']].push(plan["product_id"])
        } else {
          map_parent_id[plan["parent_product"]] = [plan["product_id"]]
        }
        return acc
      }, {})
      const get_recursive_base = (parent_id) => {
        console.log("Current parent_id:", parent_id);
        if (Object.hasOwn(map_base_by_id, parent_id)) {
          return map_base_by_id[parent_id];
        } else {
          let new_parent_ids = Object.keys(map_parent_id).filter((k) => {
            return map_parent_id[k].indexOf(parent_id) > -1;
          });

          console.log("New parent IDs:", new_parent_ids);

          if (new_parent_ids.length === 0) {
            console.warn("No parent ID found for:", parent_id);
            return null;
          }

          return get_recursive_base(new_parent_ids[0]);
        }
      };
      let _plans = response_ammounts.plans.reduce((plan_maped, item) => {
        if (item["parent_product"] == 0) {
          if (!plan_maped[item["application"]]) {
            plan_maped[item["application"]] = { base: [], addon: {} };
          }
          plan_maped[item["application"]]["base"].push(item);
        } else {
          let application = Object.hasOwn(map_base_by_id, item["parent_product"])
            ? map_base_by_id[item["parent_product"]]
            : get_recursive_base(item["parent_product"]);

          if (!application || !plan_maped[application]) {
            console.warn(`Application not found for parent_product: ${item["parent_product"]}`);
            return plan_maped;
          }

          if (!plan_maped[application]["addon"][item["tag_product"]]) {
            plan_maped[application]["addon"][item["tag_product"]] = [item];
          } else {
            plan_maped[application]["addon"][item["tag_product"]].push(item);
          }
        }
        return plan_maped;
      }, initialMap);
      // let _plans = response_ammounts.plans
      //   .sort((a, b) => {
      //     return a.date_created.localeCompare(b.date_created);
      //   })
      //   .filter(p => {
      //     if (dateStart == "" && dateEnd == "") return p

      //     var date = new Date(p.date_created);
      //     if (dateEnd == "")
      //       return (date >= dateStart);

      //     if (dateStart == "")
      //       return (date <= dateEnd);

      //     return (date >= dateStart && date <= dateEnd);
      //   }).reduce((plan_maped, item) => {
      //     if (plan_maped[item["application"]] === undefined) {
      //       plan_maped[item["application"]] = {
      //         base: {},
      //         addon: {},
      //       };
      //     }

      //     if (item["tag_product"] === undefined || item["tag_product"] === null) {
      //       if (plan_maped[item["application"]]["base"][item.name] === undefined) {
      //         plan_maped[item["application"]]["base"][item.name] = { ...item, "plans": [], "sub": [] }
      //       }

      //       plan_maped[item["application"]]["base"][item.name]["plans"].push(item)
      //       if (plan_maped[item["application"]]["base"][item.name]["sub"].indexOf(item['sub']) == -1)
      //         plan_maped[item["application"]]["base"][item.name]["sub"].push(item['sub'])

      //       _plans_list[item.name] = plan_maped[item["application"]]["base"][item.name]

      //     } else {
      //       if (plan_maped[item["application"]]["addon"][item["tag_product"]] === undefined) {
      //         plan_maped[item["application"]]["addon"][item["tag_product"]] = {}
      //       }

      //       if (plan_maped[item["application"]]["addon"][item["tag_product"]][item.name] === undefined) {
      //         plan_maped[item["application"]]["addon"][item["tag_product"]][item.name] = { ...item, "plans": [], "sub": [] }
      //       }

      //       plan_maped[item["application"]]["addon"][item["tag_product"]][item.name]["plans"].push(item)
      //       if (plan_maped[item["application"]]["addon"][item["tag_product"]][item.name]["sub"].indexOf(item['sub']) == -1)
      //         plan_maped[item["application"]]["addon"][item["tag_product"]][item.name]["sub"].push(item['sub'])

      //       _plans_list[item.name] = plan_maped[item["application"]]["addon"][item["tag_product"]][item.name]
      //     }
      //     return plan_maped;
      //   }, {});

      setTablePlans(response_ammounts.plans.map((item, i) => {
        return {
          'key': i,
          'product': item['application'],
          'plan': item['name_plan'],
          'amt': item['count_id'],
          'amt_sub': item['sub_count'],
          'type': item['type_product'],
          'tag': item['parent_product'] === 0 ? 'base' : item['tag_product']
        }
      }))
      setPlansAmmount(_plans);
    }
  }
  let max_value = 0

  /*   useEffect(() => {
      if(fixeSizeCreateByDay){
        const tmpConf = { ...CONFIG_LINE_BY_DAY, yAxis: { amt: { max: Math.round(max_value / 10) * 10, min: 0 } } };
        setCONFIG_LINE_BY_DAY(tmpConf);
      }else if(fixeSizeCreateByDay!=null){
        const tmpConf = { ...CONFIG_LINE_BY_DAY };
        delete tmpConf['yAxis'];
        setCONFIG_LINE_BY_DAY(tmpConf);
      }
    }, [fixeSizeCreateByDay]) */

  const set_filter_config_line_by_day = () => {
    const map_days = {}
    let dStart, dEnd;

    if (dateStart != "") dStart = moment(dateStart)
    else dStart = moment().add(-30, 'days');

    if (dateEnd != "") dEnd = moment(dateEnd)
    else dEnd = moment()

    // const max_value = response_ammounts.created_by_day.reduce(
    //   (acc, itemArr) => {
    //     const max_val_o = itemArr.reduce((acc2, item2) => {

    //       const date = moment(item2.days);
    //       const is_between = date.isBetween(dStart, dEnd)
    //       if( is_between){
    //         if(item2['type'] !== undefined){
    //           if(acc2[item2.type]===undefined){
    //             acc2[item2.type] = item2["amt"]
    //           }else{
    //             acc2[item2.type] += item2["amt"]
    //           }
    //         }else{
    //           acc2['free'] = Math.max(acc2['free'],item2["amt"])
    //         }
    //         if (map_days[item2["days"]] === undefined) map_days[item2["days"]] = item2['type']===undefined?0:item2['type']
    //       }

    //       // if (item2["amt"] > acc2) return item2["amt"];
    //       return acc2;

    //     }, {'free':0,'paid':0});
    //     const _max_val = Math.max(max_val_o['free'],max_val_o['paid'])
    //     if (_max_val > acc) return _max_val;
    //     else return acc;
    //   },
    //   0
    // );
    const _data = response_ammounts.created_by_day.map(arr => {
      const _obj = arr
      // const _obj = arr.filter(p => {
      //   if (p.name === 'lifetime' ) return false
      //   const date = moment(p.days);
      //   const is_between = date.isBetween(dStart, dEnd)
      //   if (model_map[p['name']] === undefined) model_map[p['name']] = p['type']===undefined?0:p['type']

      //   return is_between
      // }).reduce((acc, item) => {

      //   if (acc[item['days']] === undefined)
      //     acc[item['days']] = { [item['name']]: item }
      //   else
      //     if (acc[item['days']][item['name']] === undefined)
      //       acc[item['days']][item['name']] = item
      //     else{
      //       acc[item['days']][item['name']].push(item)
      //       debugger
      //     }

      //   return acc
      // }, {})
      // Object.keys(map_days).forEach(_dd=>{
      //   if(_obj[_dd]===undefined){
      //     _obj[_dd] = Object.keys(model_map).reduce((_a,_m)=>{
      //       _a[_m] = {"name":_m,"amt":0,"days":_dd}
      //       if(model_map[_m]!=0) _a[_m]['type'] = model_map[_m]
      //       return _a
      //     },{})
      //   }
      // })
      // Object.keys(_obj).map(_d => {
      //   Object.keys(model_map).map(_m=>{
      //     if(_obj[_d][_m]===undefined) {
      //       _obj[_d][_m] = {"name":_m,"amt":0,"days":_d}
      //       if(model_map[_m]!=0) _obj[_d][_m]['type'] = model_map[_m]
      //     }
      //   })
      // })
      let legens = legendCreatByDay
      let newArray = Object.keys(_obj).reduce((_acc, _dt) => {
        let _m_v = 0
        let _type_max = [0, 0]
        Object.keys(_obj[_dt]).forEach(_t => {
          let new_obj = _obj[_dt][_t]
          if (legens[_t] === undefined) legens[_t] = true
          const _date = moment(_dt, "YYYY-MM-DD").format("DD/MM/YYYY")
          new_obj['days'] = _date
          _acc.push(new_obj)
          if (new_obj['type'] === undefined)
            _m_v = _m_v + new_obj['amt']
          else
            _type_max[new_obj['type'] === 'free' ? 0 : 1] += new_obj['amt']
        })
        max_value = Math.max(max_value, _m_v)
        max_value = Math.max(max_value, _type_max[0])
        max_value = Math.max(max_value, _type_max[1])

        return _acc
      }, []).sort((a, b) => {
        if (b.type)
          return (b.type.localeCompare(a.type)) + moment(a.days, "DD/MM/YYYY").diff(moment(b.days, "DD/MM/YYYY"))
        else
          return moment(a.days, "DD/MM/YYYY").diff(moment(b.days, "DD/MM/YYYY"))
      })
      setLegendCreatByDay(legens)
      return newArray
    })

    setCONFIG_LINE_BY_DAY({
      ...CONFIG_LINE_BY_DAY,
      xAxis: { days: { max: moment().add(-30, 'days').format("YYYY-MM-DD"), min: moment().format("YYYY-MM-DD") } },
      data: _data
    });
  }
  const update_recorrence = () => {
    let tabs_next_charge = { "NaT": {} }
    let data_start = ""
    let data_end = ""
    if (dateEnd != "") data_end = moment(dateEnd)
    else data_end = moment().add(7, 'days')
    if (dateStart != "") data_start = moment(dateStart)
    else data_start = moment().add(-1, 'days')


    // const range_dates = Array((dateEnd == "" ) ? 5 : moment(dateEnd).diff(dateStart==""?moment():moment(dateStart), 'days') ).fill(0)
    const plans = response_ammounts.recorring.filter(v => v.type === "paid")
      .sort((a, b) => {
        return moment.utc(a.next_charge).diff(moment.utc(b.next_charge))
      })
    //filter(v => (moment().isSameOrAfter(moment(v.next_charge)) || v.next_charge === 'NaT')).
    tabs_next_charge = plans.reduce((acc, item) => {
      if (item['next_charge_by_day'] in acc) {
        if (item.sub in acc[item['next_charge_by_day']])
          acc[item['next_charge_by_day']][item.sub].push(item)
        else
          acc[item['next_charge_by_day']][item.sub] = [item]
      } else {
        acc[item['next_charge_by_day']] = { [item.sub]: [item] }
      }
      return acc
    }, {})
    tabs_next_charge = Object.keys(tabs_next_charge).sort((a, b) => {
      if (a == "NaT") return -1
      return moment.utc(a).diff(moment.utc(b))
    }).reduce((a, c) => {
      // if (c === "NaT" || moment(c).isBetween(data_start, data_end))
      a[c == "NaT" ? c : moment(c).add(1, 'day').format("DD/MM/YYYY")] = tabs_next_charge[c]
      return a
    }, {})

    setNextChargeUsers(tabs_next_charge)
  }

  useEffect(() => {
    if (response_ammounts) {


      updateOverviewAmmount()
      set_filter_config_line_by_day()

      update_pie_graph()
      setLoadingData(false);
      update_recorrence()

    }

  }, [response_ammounts, response_error_ammounts]);

  const update_pie_graph = () => {
    if (!response_ammounts) return 0

    setCONFIG_PIE_PROFESSION({
      ...CONFIG_PIE_PROFESSION,
      data: convert_data_to_graph_profession(
        response_ammounts.professions["geral"]['name']
      ),
    });
    setProfessions(response_ammounts.professions)

    const self = this;
    G2.registerInteraction("custom-association-filter", {
      showEnable: [
        {
          trigger: "element:mouseenter",
          action: "cursor:pointer",
        },
        {
          trigger: "element:mouseleave",
          action: "cursor:default",
        },
      ],
      start: [
        {
          trigger: "element:click",
          action: (context) => {
            const { view, event } = context;
            const _profession = event.data.data.profession;
            const updateGraph = updateOnClick.bind(self);
            updateGraph(_profession);
          },
        },
      ],
    });
  }

  const updateData = () => {
    setLoadingData(true)
    request_ammounts({
      url: ADMIN_GET_PLANS_AMOUNTS(),
      method: "GET",
    });
  }

  const handleFilterDate = (force_date_start = -1, force_date_end = -1) => {
    setLoadingData(true);
    let date_start = "";
    let date_end = "";
    if (dateStart != "" && force_date_start == -1) date_start = moment(dateStart).format("YYYY-MM-DD");
    if (dateEnd != "" && force_date_end == -1) date_end = moment(dateEnd).format("YYYY-MM-DD");
    if (date_start && date_end) {
      if (moment(date_start).isAfter(date_end)) {
        setDateEnd("");
        setDateStart("");
        setLoadingData(false);
        return 0;
      }
    }
    setLoadingData(true)
    request_ammounts({
      url: ADMIN_GET_PLANS_AMOUNTS(date_start, date_end),
      method: "GET",
    });
    console.log(date_start);
    console.log(date_end);
    // updateOverviewAmmount()
    // setLoadingData(false);
    // update_recorrence()
    // set_filter_config_line_by_day()
  };

  const convert_data_to_graph_profession = (data) => {
    let total = 0;
    return Object.keys(data)
      .map((key) => {
        total = total + data[key];
        return {
          profession: (key == "" || key == "null") ? "vazio" : key,
          amt: data[key],
        };
      })
      .sort((a, b) => b.amt - a.amt)
      .map((item) => {
        item["percent"] = `${Math.round((item["amt"] / total) * 10000) / 100}%`;
        return item;
      });
  };

  const handleAmounts = () => {
    setDateStart("");
    setDateEnd("");
    handleFilterDate("", "");
    setLoadingData(true)
  };

  // useEffect(() => {
  //   if (dateStart=="" || dateEnd=="") {
  //     handleFilterDate();
  //   }
  // }, [dateEnd, dateStart])

  // const pattern_ammount_cards = (list_counters) => {
  //   let init_obj = {};
  //   if (Object.keys(list_counters).length > 1) {
  //     init_obj["Total"] = 0;
  //   }
  //   const response_obj = Object.keys(list_counters).reduce((objacc, item) => {
  //     if ("Total" in objacc) objacc["Total"] += list_counters[item]['plans'].length;
  //     if (list_counters[item]['plans'].length != list_counters[item]['sub'].length) {
  //       objacc[list_counters[item]["name"]] = [list_counters[item]['plans'].length, list_counters[item]['sub'].length];
  //     } else {
  //       objacc[list_counters[item]["name"]] = list_counters[item]['plans'].length;
  //     }

  //     return objacc;
  //   }, init_obj);

  //   return response_obj;
  // };
  const pattern_ammount_cards = (list_counters) => {
    let init_obj = {};
    if (list_counters.length > 1) {
      init_obj["Total"] = 0;
    }
    const response_obj = list_counters.reduce((objacc, item) => {
      if ("Total" in objacc) objacc["Total"] += item["count_id"];
      if ("sub_count" in item && item["count_id"] != item["sub_count"]) {
        objacc[item["name_plan"]] = [item["count_id"], item["sub_count"]];
      } else {
        objacc[item["name_plan"]] = item["count_id"];
      }

      return objacc;
    }, init_obj);

    return response_obj;
  };

  const renderCardsAmount = (plans_amounts) => {
    let content_dynamic = Object.keys(plans_amounts).reduce((acc, item, k) => {
      let title = (item in map_application_name
        ? map_application_name[item]
        : item)
      let _content = (
        <Col
          id="products"
          span={(12 / Object.keys(plans_amounts).length) * 2}
          key={k}
          flex="auto"
          style={{ height: "100%", alignItem: "stretch" }}
        >

          <Row >
            <AmountCards
              className={classes.CardAmmount}
              size_var={Object.keys(plans_amounts).length}
              title={
                (item != "annual" ? "Plano Base - " + title : title)

              }
              plan={pattern_ammount_cards(plans_amounts[item]["base"])}
            />
          </Row>

          <Row>
            {Object.keys(plans_amounts[item]["addon"]).map(
              (kaddon, _k, full_item) => (
                <Col
                  key={_k}
                  id="item"
                  span={full_item.length == 1 ? "none" : 12}
                  flex="auto"
                >
                  <AmountCards
                    className={classes.CardAmmount}
                    size_var={Object.keys(plans_amounts).length}
                    title={
                      (["grand", "individual", "patient", "parent"].indexOf(kaddon) == -1 ? "Pacote " : "") +
                      (kaddon in map_application_name
                        ? map_application_name[kaddon]
                        : kaddon)
                    }
                    plan={pattern_ammount_cards(
                      plans_amounts[item]["addon"][kaddon]
                    )}
                  />
                </Col>
              )
            )}
          </Row>
        </Col>

      );
      acc.push(_content);
      return acc;
    }, []);

    return (
      <>
        <Row gutter={[6, 6]} wrap={false}>{content_dynamic}</Row>
      </>
    );
  };
  const downloadCSV = () => {
    setLoadingCSV(true);
    download_csv({
      url: ADMIN_GET_CSV_OVERVIEW()
      , method: "GET", Headers: { "Access-Control-Allow-Origin": "*" }
    });
    // if (dateStart != "" || dateEnd != "") {
    //   let date_start = "";
    //   let date_end = "";
    //   if (dateStart != "")
    //     date_start = moment(dateStart)
    //       .set({ hour: 0, minute: 0, second: 0 })
    //       .format("YYYY-MM-DD HH:mm:ss");
    //   if (dateEnd != "")
    //     date_end = moment(dateEnd)
    //       .set({ hour: 23, minute: 59, second: 59 })
    //       .format("YYYY-MM-DD HH:mm:ss");
    //   console.log(date_start);
    //   console.log(date_end);
    //   download_csv({
    //     url: ADMIN_GET_CSV_OVERVIEW(date_start, date_end)
    //   });
    // } else {
    //   download_csv({
    //     url: ADMIN_GET_CSV_OVERVIEW()
    //   });
    // }
  };

  const getSummary = (pageData) => {
    return Object.keys(plansAmmount).map((product) => {
      const total = pageData.reduce((acc, item) => { return (item['product'] === product) ? acc + item.amt : acc }, 0)
      return <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>{product}<Divider type='vertical' />{total}</Table.Summary.Cell>
          {
            ['type', 'tag'].map((type) => {
              const totalTypes = pageData.reduce((acc, item) => { if (acc.indexOf(item[type]) === -1 && item['product'] === product) acc.push(item[type]); return acc }, [])
              return <>{totalTypes.map((_typeItem) => {
                const _total = pageData.reduce((acc, item) => { return (item['product'] === product && item[type] === _typeItem) ? acc + item.amt : acc }, 0)
                return <Table.Summary.Cell index={0}>{_typeItem}<Divider type='vertical' />{_total}</Table.Summary.Cell>
              })
              }</>
            })
          }
        </Table.Summary.Row>
      </>
    })
  }


  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <Row gutter={12}>
            <h1>Dashboard</h1>
          </Row>
          <Row gutter={10}>
            <Col span={2.5} key="1">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Data Inicio (a partir)"
                selected={dateStart}
                onChange={(date) => setDateStart(date)}
              />
            </Col>
            <Col span={2.5} key="2">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Data Fim (até)"
                selected={dateEnd}
                onChange={(date) => setDateEnd(date)}
              />
            </Col>
            <Col span={2.5} key="3">
              <Button type="primary" onClick={() => handleFilterDate()}>
                <FontAwesomeIcon
                  icon={loadingData ? faSpinner : faSearch}
                  spin={loadingData}
                />
              </Button>
            </Col>
            {(dateStart !== "" ||
              dateEnd !== "") ? (
              <Col span={2.5} key="4">
                <Button type="primary" onClick={() => handleAmounts()}>
                  Limpar Datas
                </Button>
              </Col>
            ) : (
              ""
            )}
            <Col span={2.5} key="5">
              <Button
                type="primary"
                onClick={() => {
                  downloadCSV();
                }}
              >
                <FontAwesomeIcon
                  icon={loadingCSV ? faSpinner : faDownload}
                  spin={loadingCSV}
                />
              </Button>
            </Col>
            <Col span={2.5} key="6">
              <Button type="primary" onClick={() => updateData()}>
                <FontAwesomeIcon
                  icon={faRetweet}
                  spin={loadingData}
                />
              </Button>
            </Col>

          </Row>
        </div>
        <Divider />
        {loadingData ? <Spinner></Spinner> :
          <>
            <div
              className={
                "col-sm" +
                (focusState == 0 ? "-6" : focusState == 1 ? "-12" : "-6 invisible")
              }
            >
              <h3>
                Número de Usuários na Base{" "}
                <Button
                  type="default"
                  onClick={() => {
                    if (focusState > 0) setFocusState(0);
                    else setFocusState(1);
                  }}
                >
                  <FontAwesomeIcon
                    icon={focusState != 1 ? faExpand : faArrowAltCircleLeft}
                    spin={loadingData}
                  />
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    if (typeShowNumbers > 0) setTypeShowNumbers(0);
                    else setTypeShowNumbers(1);
                  }}
                >
                  <FontAwesomeIcon
                    icon={[faTable, faIdCardAlt][typeShowNumbers]}
                  />
                </Button>
              </h3>

              {Object.keys(plansAmmount).length > 0 ? [renderCardsAmount(plansAmmount), <Table columns={tableCollums} dataSource={tablePlans} summary={getSummary}></Table>][typeShowNumbers] : <Spinner />}

            </div>
            <div
              className={
                "col-sm" +
                (focusState == 0 ? "-6" : focusState == 2 ? "-12" : "-6 invisible")
              }
            >
              <Row justify="start" gutter={12}>
                <Col key="1">
                  <h3>Planos/Usuários Criados por Dia </h3>
                </Col>
                <Col key="2">
                  <Button
                    type="default"
                    onClick={() => {
                      if (focusState > 0) setFocusState(0);
                      else setFocusState(2);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={focusState != 2 ? faExpand : faArrowAltCircleLeft}
                      spin={loadingData}
                    />
                  </Button>
                  <Button
                    type="default"
                    onClick={() => {
                      setToogleLegends(!toogleLegends)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={toogleLegends ? faCheckCircle : faCircle}
                    />
                  </Button>
                  {/* <Button
                    type="default"
                    onClick={() => {
                      setFixeSizeCreateByDay(!fixeSizeCreateByDay)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={fixeSizeCreateByDay ? faCircle : faAdjust}
                    />
                  </Button> */}
                </Col>
                <Col key="3">
                  <Select
                    aria-label="select"
                    placeholder="Posição da Legenda"
                    dropdownMatchSelectWidth={false}
                    onChange={(v) => {
                      setCONFIG_LINE_BY_DAY({
                        ...CONFIG_LINE_BY_DAY,
                        legend: { maxRow: 5, position: v },
                      });
                    }}
                    size="middle"
                  >
                    {[
                      "top",
                      "top-left",
                      "top-right",
                      "left",
                      "left-top",
                      "left-bottom",
                      "right",
                      "right-top",
                      "right-bottom",
                      "bottom",
                      "bottom-left",
                      "bottom-right",
                    ].map((opt, i) => {
                      return (
                        <Select.Option value={opt} key={i}>
                          {opt}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              {CONFIG_LINE_BY_DAY.data && CONFIG_LINE_BY_DAY.data.length > 0 ? (
                <DualAxes {...CONFIG_LINE_BY_DAY} />
              ) : (
                <Spinner />
              )}
            </div>
            <div className={"col-sm-12"}>
              <Row justify="start" gutter={12}>
                <Col key="1">
                  <h3>
                    Número de Usuários por{" "}
                    {graphPieUpdate ? graphPieUpdate : "Profissão"}
                  </h3>
                </Col>
                <Col key="2">
                  <Button
                    type="default"
                    onClick={() => {
                      if (focusState > 0) setFocusState(0);
                      else setFocusState(3);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={focusState != 3 ? faExpand : faArrowAltCircleLeft}
                      spin={loadingData}
                    />
                  </Button>
                </Col>
                <Col key="3">
                  <Button
                    type="default"
                    onClick={() => {
                      if (typePieChart > 0) setTypePieChart(0);
                      else setTypePieChart(1);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={typePieChart != 1 ? faTable : faArrowAltCircleLeft}
                      spin={loadingData}
                    />
                  </Button>
                </Col>
                <Col key="4">
                  <Button
                    type="primary"
                    onClick={() => {
                      setgraphPieUpdate("");
                    }}
                    disabled={!graphPieUpdate}
                  >
                    Resetar
                  </Button>
                </Col>
              </Row>

              <Row justify="start" gutter={6}>
                <Col span={12} key="1">
                  <Row justify="start" gutter={6}>

                    <Col span={12} key="1">
                      <h5>Planos</h5>
                      {professions && Object.keys(professions).length > 0 ? (
                        Object.keys(professions['per_plan'])
                          .map((item, k) => {
                            return (
                              <Button
                                type="default"
                                key={k}
                                onClick={() => {
                                  setgraphPieUpdate(item);
                                }}
                              >
                                {item}
                              </Button>
                            );
                          })
                      ) : (
                        <Spinner />
                      )}
                    </Col>
                    <Col span={12} key="2">
                      <h5>Profissões</h5>
                      {professions && Object.keys(professions).length > 0 ? (
                        Object.keys(professions['geral'].profession)
                          .map((f) => {
                            if (f == '') return 'Vazio'
                            else return f
                          })
                          .map((item, k) => {
                            return (
                              <Button
                                type="default"
                                key={k}
                                onClick={() => {
                                  setgraphPieUpdate(item);
                                }}
                              >
                                {item}
                              </Button>
                            );
                          })

                      ) : (
                        <Spinner />
                      )}
                    </Col>
                  </Row>


                  {Object.keys(professions).length > 0 ? (
                    (typePieChart == 0) ?
                      <Pie {...CONFIG_PIE_PROFESSION} />
                      : <Table
                        dataSource={CONFIG_PIE_PROFESSION.data.map((v, i) => {
                          v["key"] = i;
                          return v;
                        })}
                        summary={(pageData) => {
                          return <>

                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>Total<Divider type='vertical' />{CONFIG_PIE_PROFESSION.data.reduce((a, p) => a + p['amt'], 0)}</Table.Summary.Cell>

                            </Table.Summary.Row>
                          </>

                        }}
                        columns={[
                          {
                            title: "Name",
                            dataIndex: "profession",
                            key: "profession",
                          },
                          { title: "Quantidade", dataIndex: "amt", key: "amt" },
                          {
                            title: "Porcentagem",
                            dataIndex: "percent",
                            key: "percent",
                          },
                        ]}
                      />
                  ) : (
                    <Spinner />
                  )}
                </Col>
                <Col span={12} key="2">
                  <h3>Recorrência (Next Charge)</h3>
                  {Object.keys(nextChargeUsers).length > 0 ? (
                    <Tabs defaultActiveKey="1" tabPosition="left" >
                      {
                        Object.keys(nextChargeUsers)/* .sort((a, b) => {
                      if (a=="NaT") return -1
                      return moment.utc(a).diff(moment.utc(b))
                    }) */.map((dataName, k) => {
                          return <TabPane tab={(dataName === "NaT" ? "Vazio" : dataName)} key={k}>
                            <Table
                              dataSource={Object.keys(nextChargeUsers[dataName]).map((v, i) => {
                                let obj = {}
                                obj['email'] = nextChargeUsers[dataName][v][0]['email']
                                obj['next_charge'] = nextChargeUsers[dataName][v][0]['next_charge']
                                let alltags = []
                                obj['children'] = nextChargeUsers[dataName][v].map((c, ii) => {
                                  let newObj = {}
                                  newObj['key'] = ii
                                  newObj['email'] = c['application_name'] + " " + c['name']
                                  newObj['next_charge'] = c['next_charge']
                                  newObj['tags'] = [(c['recurrence'] == 'true' ? "Recorrente" : "Único")]
                                  if (c['is_legacy'] == 'true') newObj['tags'].push("Legado")

                                  newObj['tags'].forEach(itemTag => {
                                    if (alltags.indexOf(itemTag) === -1)
                                      alltags.push(itemTag)
                                  })
                                  return newObj
                                })

                                obj["tags"] = alltags;
                                obj["key"] = i;
                                return obj;
                              })}
                              columns={[
                                {
                                  title: "E-mail",
                                  dataIndex: "email",
                                  key: "email",
                                  render: text => (text.indexOf('@') > -1 ? <Link
                                    to={{
                                      pathname: routes.R_GET_USER_DETAILS(text)
                                    }} >{text}</Link> : text),

                                },
                                { title: "Next Charge", dataIndex: "next_charge", key: "next_charge" },
                                {
                                  title: "Tags",
                                  dataIndex: "tags",
                                  key: "tags",
                                  render: (tags) => (
                                    <span>
                                      {tags.map((tag, ii) => {
                                        let color = map_color_tag[tag]
                                        return (
                                          <Tag color={color} key={ii}>
                                            {tag.toUpperCase()}
                                          </Tag>
                                        );
                                      })}
                                    </span>
                                  ),
                                },
                              ]}
                            />
                          </TabPane>
                        })
                      }
                    </Tabs>
                  ) : (
                    <Spinner />
                  )}
                </Col>
              </Row>
            </div>
          </>
        }
      </div>
    </div>
  );
}
