import React, { useState } from "react";
import { Modal, Typography, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";
import axios from "axios";
import { ADMIN_GAMES } from "../../../constants/api";
import { R_ACTIVITIES } from "../../../constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { Title, Paragraph } = Typography;

const ModalDeleteActivities = ({
  open,
  handleCancel,
  game,
}) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const history = useHistory();

  const deleteGame = (id) => {
    setButtonDisable(true);
    if (!id) {
      console.log("ID do jogo não fornecido");
      return;
    }

    const url = `${ADMIN_GAMES}/${id}`;
    console.log("URL para DELETE:", url);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return axios.delete(url, {
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken,
            "Content-Type": "application/json",
          },
        });
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("status 200");
          handleCancel();
          history.push(R_ACTIVITIES);
          window.location.reload();
        } else {
          console.log(`Erro na exclusão do jogo. Status: ${response.status}`);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || error.message || "Erro desconhecido";
        console.log("Erro ao deletar o jogo", errorMessage);
      });
  };

  return (
    <Modal
      width={1000}
      height={407}
      modalRender={(modal) => (
        <div style={{ borderRadius: "14px", overflow: "hidden" }}>{modal}</div>
      )}
      centered
      open={open}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
          style={{
            width: "250px",
            color: "#0D6E7A",
            fontSize: "16px",
            fontWeight: "500",
            borderRadius: "10px",
            border: "2px solid #0D6E7A",
            marginRight: "10px",
            textAlign: "center",
            padding: "6px 20px",
            lineHeight: "32px",
            boxSizing: "border-box",
            display: "inline-block",
            height: "40px",
            verticalAlign: "middle",
            margin: "10px 10px 20px",
          }}
        >
          Cancelar
        </Button>,
        <Button
          key="delete"
          type="primary"
          danger
          onClick={() => deleteGame(game.id)}
          disabled={buttonDisable}
          style={{
            width: "250px",
            borderRadius: "10px",
            marginLeft: "10px",
            padding: "6px 20px",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "32px",
            textAlign: "center",
            boxSizing: "border-box",
            display: "inline-block",
            height: "40px",
            verticalAlign: "middle",
            marginRight: "15px",
            marginBottom: "12px",
          }}
        >
          Confirmar exclusão
        </Button>,
      ]}
      closeIcon={
        <Button
          shape="circle"
          icon={<CloseOutlined />}
          style={{
            color: "#0D6E7A",
            fontSize: "20px",
            border: "2px solid #0D6E7A",
            background: "transparent",
            borderRadius: "50%",
            padding: "5px",
            boxSizing: "border-box",
            display: "inline-block",
          }}
          onClick={handleCancel}
        />
      }
    >
      <Title
        level={4}
        style={{
          color: "#0D6E7A",
          fontSize: "24px",
          fontWeight: "600",
          margin: "15px 0 30px 20px",
        }}
      >
        Confirmar exclusão
      </Title>
      <Paragraph
        style={{
          color: "#4D4D4D",
          fontSize: "16px",
          fontWeight: "400",
          margin: "15px 0 30px 20px",
        }}
      >
        Deseja excluir a atividade{" "}
        <strong style={{ fontWeight: "700" }}>{game?.name}</strong> ? <br />
        Após a exclusão, ela não poderá ser recuperada.
      </Paragraph>
    </Modal>
  );
};

export default ModalDeleteActivities;
