import React, { useState, useEffect } from "react";
import AddActivitiesPage1 from "./AddActivitiesPage1";
import AddActivitiesPage2 from "./AddActivitiesPage2";
import { GAME_API } from "../../../constants/api";
import { ADMIN_GAMES } from "../../../constants/api";
import { R_ACTIVITIES } from "../../../constants/routes";
import axios from "axios";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AddActivities = (props) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [currentPageState, setCurrentPageState] = useState("page1");
  const [pageControl, setPageControl] = useState(true);
  const [errorField, setErrorField] = useState({});
  const [games, setGames] = useState(null);
  const [gamesMapField, setGamesMapField] = useState(null);
  const history = useHistory();
  const [pathValid, setPathValid] = useState(null);
  const [gameImage, setGameImage] = useState(null);
  const [pdfAudio, setPdfAudio] = useState(null);

  const [gameData, setGameData] = useState(
    props.game || {
      infos: [
        {
          title: "Habilidades Estimuladas",
          body: "",
        },
        {
          title: "Quem se beneficia com este jogo ?",
          body: "",
        },
        {
          title: "O que você pode melhorar ?",
          body: "",
        },
      ],
    }
  );

  const updateValidateField = async () => {
    const hasError = await validatePage(currentPageState);
    setPageControl(true);
  };

  useEffect(() => {
    if (props.game && games) {
      updateValidateField();
    }
  }, [gameData, games]);

  useEffect(() => {
    if (!games) {
      const gamesLocal = localStorage.getItem("games");
      if (gamesLocal && gamesLocal.length > 1) {
        setGames(JSON.parse(gamesLocal));
      }
    } else {
      if (!props.game) {
        setGameData((prevState) => ({
          ...prevState,
          id: games.reduce((finallyId, game) => {
            return game.id >= finallyId ? parseInt(game.id) + 1 : finallyId;
          }, 1),
        }));
      } else {
        if (props.game.infos.length < 3) {
          setGameData((prevState) => ({
            ...prevState,
            infos: [
              JSON.parse(JSON.stringify(props.game.infos[0])),
              JSON.parse(JSON.stringify(props.game.infos[0])),
              JSON.parse(JSON.stringify(props.game.infos[0])),
            ],
          }));
        }
      }
      setPageControl(true);
      if (games.length > 0) {
        const gameMap = Object.keys(games[0]).reduce((mapGame, atribute) => {
          mapGame[atribute] = games.reduce((ability, game) => {
            if (
              Array.isArray(game[atribute]) &&
              ["infos", "credits"].indexOf(atribute) === 0
            ) {
              return [...ability, ...game[atribute]];
            } else if (
              typeof game[atribute] === "string" &&
              atribute !== "id"
            ) {
              return [...ability, game[atribute]];
            } else if (atribute === "id") {
              return [...ability, { name: game["name"], id: game["id"] }];
            } else if (atribute === "credits") {
              if (Array.isArray(ability)) {
                ability = {};
              }
              ability["elaboration"] = [
                ...(ability["elaboration"] ? ability["elaboration"] : []),
                ...game[atribute]["elaboration"],
              ];
              ability["development"] = [
                ...(ability["development"] ? ability["development"] : []),
                ...game[atribute]["development"],
              ];
              ability["illustration"] = [
                ...(ability["illustration"] ? ability["illustration"] : []),
                ...game[atribute]["illustration"],
              ];
            }
            return ability;
          }, []);
          if (Array.isArray(mapGame[atribute])) {
            mapGame[atribute] = mapGame[atribute].filter(
              (ability, index, self) =>
                ability && self.indexOf(ability) === index
            );
          } else {
            mapGame[atribute] = Object.keys(mapGame[atribute]).reduce(
              (finallyMap, item) => {
                finallyMap[item] = mapGame[atribute][item].filter(
                  (ability, index, self) => self.indexOf(ability) === index
                );
                return finallyMap;
              },
              {}
            );
          }
          return mapGame;
        }, {});
        setGamesMapField(gameMap);
      }
    }
  }, [games]);

  const requiredFields = {
    page1: [
      "id",
      "name",
      "engine",
      "type",
      "difficulty",
      "language",
      "qtd_levels",
      "release_date",
      "version",
      "visible",
      "path",
      "main_ability_tag",
      "time",
    ],
    page2: ["credits"],
  };
  const validatePage = async (page) => {
    let hasError = false;
    const newErrorField = await requiredFields[page].reduce(
      async (finallyObjPromesse, gameKey) => {
        const finallyObj = await finallyObjPromesse;
        finallyObj[gameKey] = await validateField(gameKey, gameData[gameKey]);
        hasError =
          hasError || (finallyObj[gameKey] && finallyObj[gameKey].length > 0);
        return finallyObj;
      },
      {}
    );
    setErrorField({ ...errorField, ...newErrorField });
    return hasError;
  };

  const nextPage = async () => {
    const hasError = await validatePage("page1");

    if (!hasError) {
      setCurrentPageState("page2");
    }
    setPageControl(true);
  };

  const backPage = () => {
    setCurrentPageState("page1");
    setPageControl(true);
  };

  const finalizeRegister = async () => {
    const hasError = await validatePage("page1");
    if (hasError) {
      setPageControl(true);
      return false;
    }
    saveGame();
  };

  const registerFlow = {
    page1: () => {
      return (
        <AddActivitiesPage1
          nextPage={nextPage}
          gameData={gameData}
          updatedGameDate={updatedGameDate}
          errorField={errorField}
          gamesMapField={gamesMapField}
          pathValid={pathValid}
          gameImage={gameImage}
          pdfAudio={pdfAudio}
        />
      );
    },
    page2: () => {
      return (
        <AddActivitiesPage2
          backPage={backPage}
          finalizeRegister={finalizeRegister}
          gameData={gameData}
          updatedGameDate={updatedGameDate}
          errorField={errorField}
          gamesMapField={gamesMapField}
        />
      );
    },
  };

  const updatedGameDate = async (keyProp, valueChange) => {
    const currentGameData = gameData;
    if (Array.isArray(keyProp)) {
      keyProp.reduce((acc, key, index) => {
        if (index === keyProp.length - 1) {
          acc[key] = valueChange;
        } else {
          if (typeof keyProp[index + 1] === "number") {
            acc[key] = acc[key] || [];
          } else {
            acc[key] = acc[key] || {};
          }
        }
        return acc[key];
      }, currentGameData);
    } else {
      currentGameData[keyProp] = valueChange;
      const errorFieldCurrent = await validateField(
        keyProp,
        currentGameData[keyProp]
      );
      setErrorField((prevState) => ({
        ...prevState,
        [keyProp]: errorFieldCurrent,
      }));
    }
    setPageControl(true);
    setGameData(currentGameData);
  };

  const loadImage = async (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve("ok");
      };
      img.onerror = () => {
        reject(new Error("Erro ao carregar imagem."));
      };
      img.src = url;
    });
  };

  const mapValidateField = {
    id: (value) => {
      if (isNaN(value)) {
        return "Id não é válido";
      }
      return null;
    },
    name: (value) => {
      if (!value) return "Campo está vazio";
      if (
        games.filter(
          (g) =>
            g.name === value &&
            (!props || !props.game || props.game.name !== value)
        ).length > 0
      ) {
        return "Nome já existe";
      }
      return null;
    },
    pathGame: async (value) => {
      try {
        const response = await fetch(GAME_API + "/" + value + "/index.html");
        console.log("path", response);
        if (!response.ok) {
          setPathValid(false);
          return "Caminho inválido. Suba primeiro o jogo no GitHub.";
        }
        setPathValid(true);
      } catch (error) {
        console.log(error);
        setPathValid(false);
        return "Caminho inválido. Suba primeiro o jogo no GitHub.";
      }
      //Requisição da imagem do jogo
      try {
        const urlImage = GAME_API + "/" + value + "/jogo_imagem_.jpg";
        const response = await loadImage(urlImage);
        if (response !== "ok") {
          setGameImage(false);
          return "O caminho não possui uma imagem válida.";
        }
        setGameImage(urlImage);
      } catch (error) {
        console.log("deu ruim", error);
        setGameImage(false);
        return "o caminho não possui uma imagem válida.";
      }
      return null;
    },
    pathAudio: async (value) => {
      try {
        const response = await fetch(GAME_API + "/" + value + "/audio.mp3");
        console.log("path", response);
        if (!response.ok) {
          setPathValid(false);
          return "Caminho inválido. Suba primeiro o jogo no GitHub.";
        }
        setPathValid(true);
      } catch (error) {
        console.log(error);
        setPathValid(false);
        return "Caminho inválido. Suba primeiro o jogo no GitHub.";
      }
      //Requisição da imagem do jogo
      try {
        const urlImage = GAME_API + "/" + value + "/audio_imagem_.jpg";
        const response = await loadImage(urlImage);
        if (response !== "ok") {
          setGameImage(false);
          return "O caminho não possui uma imagem válida.";
        }
        setGameImage(urlImage);
      } catch (error) {
        console.log("deu ruim", error);
        setGameImage(false);
        return "o caminho não possui uma imagem válida.";
      }

      try {
        const urlPdf = GAME_API + "/" + value + "/gabarito.pdf";
        const response = await fetch(urlPdf);
        if (!response.ok) {
          setPdfAudio(false);
          return "O caminho não possui um gabarito válido.";
        }
        setPdfAudio(urlPdf);
      } catch (error) {
        console.log("deu ruim", error);
        setPdfAudio(false);
        return "O caminho não possui um gabarito válido.";
      }

      return null;
    },
    path: async (value) => {
      if (!value?.toString().length > 0) {
        setPathValid(false);
        return "Este campo não pode permanecer vazio";
      }

      const gameType = "path" + gameData["type"];
      if (gameType in mapValidateField) {
        return mapValidateField[gameType](value);
      } else {
        return "O caminho do tipo da atividade não pode ser testado";
      }
      return null;
    },
    default: async (value) => {
      console.log("value", value);
      if (value === undefined) return "Este campo não pode permanecer vazio";
      return (
        !(value?.toString().length > 0) &&
        "Este campo não pode permanecer vazio"
      );
    },
  };

  const validateField = async (gameKey, gameField) => {
    if (mapValidateField.hasOwnProperty(gameKey)) {
      return await mapValidateField[gameKey](gameField);
    }
    return await mapValidateField["default"](gameField);
  };

  const saveGame = () => {
    if (props.saveGame) {
      props.saveGame(gameData);
      return false;
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return axios.post(
          ADMIN_GAMES,
          { game: gameData },
          {
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          console.log("status 200");
          history.push(R_ACTIVITIES);
        }
      });
  };

  useEffect(() => {
    if (
      pageControl &&
      currentPageState in registerFlow &&
      gameData &&
      gameData.id
    ) {
      setCurrentPage(registerFlow[currentPageState]);
      setPageControl(false);
    }
  }, [currentPageState, currentPage, pageControl, errorField, gameData]);

  return <div>{currentPage}</div>;
};

export default AddActivities;
