import React from "react";
import classes from "./Aside.module.scss";
import { Link, Route } from "react-router-dom";
import * as routes from "./../../constants/routes";
import { ReactComponent as Triangulo } from "../../assets/images/right_nav_arrow.svg";
import { ReactComponent as Triangulo2 } from "../../assets/images/left_nav_arrow.svg";
import {
  faShoppingCart,
  faCog,
  faUser,
  faGamepad
} from "@fortawesome/free-solid-svg-icons";
import { Collapse,Button } from "antd";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Aside = ({ className }) => {
  const { Panel } = Collapse;
  const history = useHistory();

  // const { t } = useTranslation();
  return (
    <>
      <aside className={`${className} + ${classes.Aside}`}>
        {/* <div
          className={classes.TitleAside}
        >{`Boas vindas`}</div> */}
        <nav className={classes.Nav}>
          <Collapse>
            <a
              // activeClassName={classes.Active}
              className={classes.Link}
              onClick={()=>{history.push(routes.R_DASHBOARD)}}
              href="#"
              key="l1"
            >
              <FontAwesomeIcon className={classes.Icon} icon={faShoppingCart} />
              <span className={classes.SetaEsquerda}>
                <Triangulo className={classes.trianguloEsquerda} />
                <Triangulo2 className={classes.trianguloDireita} />
                {/* <img src={triangulo}/> */}
                {"Dashboard"}
              </span>
            </a>
            <Panel
              header="AFINANDO O CÉREBRO"
              key="1"
              className={classes.custom}
              style={{ padding: "2px" }}
            >
              <Link
                // activeClassName={classes.Active}
                className={classes.Link}
                to={routes.R_PLANS_USERS}
                style={{ padding: "none" }}
              >
                <FontAwesomeIcon className={classes.Icon} icon={faUser} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Planos do usuário"}
                </span>
              </Link>
              <Link
                // activeClassName={classes.Active}
                className={classes.Link}
                to={routes.R_PLANS_CANCEL}
                style={{ padding: "none" }}
              >
                <FontAwesomeIcon className={classes.Icon} icon={faUser} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Cancelamentos"}
                </span>
              </Link>

              <Link
                // activeClassName={classes.Active}
                className={classes.Link}
                to={routes.R_DELETE_USER}
              >
                <FontAwesomeIcon className={classes.Icon} icon={faCog} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Excluir Usuário"}
                </span>
              </Link>
            </Panel>
            <Panel header="AUDIOFOCO" key="2">
              <Link
                // activeClassName={classes.Active}
                className={classes.Link}
                to={routes.R_COMPANIES}
              >
                <FontAwesomeIcon className={classes.Icon} icon={faUser} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Empresas Cadastradas"}
                </span>
              </Link>
              <Link
                // activeClassName={classes.Active}
                className={classes.Link}
                to={routes.R_AUDIOFOCO_USERS}
              >
                <FontAwesomeIcon className={classes.Icon} icon={faUser} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Usuários"}
                </span>
              </Link>
            </Panel>
            <Panel header="RECOMPENSAS" key="3">
              <Link className={classes.Link} to={routes.R_REWARDS}>
                <FontAwesomeIcon className={classes.Icon} icon={faUser} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Gerenciar Recompensas"}
                </span>
              </Link>
              <Link className={classes.Link} to={routes.R_ADD_REWARDS}>
                <FontAwesomeIcon className={classes.Icon} icon={faUser} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Adicionar Recompensas"}
                </span>
              </Link>
            </Panel>
            <Panel header="ATIVIDADES" key="4">
              <Link className={classes.Link} to={routes.R_ACTIVITIES}>
                <FontAwesomeIcon className={classes.Icon} icon={faGamepad} />
                <span className={classes.SetaEsquerda}>
                  <Triangulo className={classes.trianguloEsquerda} />
                  <Triangulo2 className={classes.trianguloDireita} />
                  {"Lista de Atividades"}
                </span>
              </Link>
            </Panel>
          </Collapse>
        </nav>
      </aside>
    </>
  );
};

export default Aside;
