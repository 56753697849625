import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActivitiesHeader from "./ActivitiesHeader";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Row,
  Col,
  Divider,
  AutoComplete,
  ConfigProvider,
  InputNumber,
  Radio,
  Typography,
  Image,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import ptBR from "antd/es/locale/pt_BR";
import moment from "moment";
import classes from "./AddActivities.module.scss";
import { GAME_API } from "../../../constants/api";

const AddActivitiesPage1 = ({
  nextPage,
  gameData,
  updatedGameDate,
  errorField,
  gamesMapField,
  pathValid,
  gameImage,
  pdfAudio,
}) => {
  const { Link } = Typography;
  const history = useHistory();
  dayjs.locale("pt-br");
  const { Option } = Select;
  const [optionsAbility, setOptionsAbility] = useState([]);
  const [optionsLanguage, setOptionsLanguage] = useState([]);
  const [optionsType, setOptionsType] = useState([]);
  const [optionsEngine, setOptionsEngine] = useState([]);
  const [optionsBehaviorTags, setOptionsBehaviorTags] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [optionsSimilarActivities, setOptionsSimilarActivities] = useState([]);
  const [optionsDifficulty, setOptionsDifficulty] = useState([]);
  const [optionsTime, setOptionsTime] = useState([]);

  // const difficultyLabels  = {
  //   1: "Dificuldade 1",
  //   2: "Dificuldade 2",
  //   3: "Dificuldade 3"
  // };
  
  useEffect(() => {
    if (gamesMapField) {
      setOptionsAbility(
        gamesMapField["main_ability_tag"]?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionsLanguage(
        gamesMapField["language"]?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionsType(
        gamesMapField["type"]?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionsEngine(
        gamesMapField["engine"]?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionsBehaviorTags(
        gamesMapField["behavior_tags"]?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionsTags(
        gamesMapField["optionsTags"]?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionsSimilarActivities(
        gamesMapField["id"]?.map((ability) => ({
          label: `Jogo: ${ability.name}`,
          value: ability.id,
        }))
      );
      setOptionsDifficulty(
        gamesMapField["difficulty"]?.map((difficulty) => ({
          label: difficulty,
          value: difficulty,
        }))
      );
      setOptionsTime(
        gamesMapField["time"]?.map((time) => ({
          label: time,
          value: time,
        }))
      );
    }
  }, [gamesMapField]);


  const handleNextPage = () => {
    nextPage();
  };

  const handleCancel = () => {
    history.push("/admin/atividades");
  };

  const labelStyle = {
    color: "#3C3C3C",
    fontSize: "16px",
    fontWeight: "500",
  };

  const inputStyle = {
    width: "100%",
  };

  const isPathValid = pathValid === true;
  const isPathInvalid = pathValid === false || errorField["path"]?.length > 0;
  const errorMessage =
    errorField["path"] ||
    (pathValid === false
      ? " Caminho inválido. Suba primeiro o jogo no GitHub."
      : "");

  return (
    <>
      <ActivitiesHeader
        text="Cadastrar/Editar  Atividade"
        style={{ marginBottom: "20px" }}
      />
      <Form
        style={{ maxWidth: 1200 }}
        size="large"
        layout="vertical"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Id Único</span>}
              name="id"
              hasFeedback={!!errorField["id"]}
              validateStatus={errorField["id"]?.length > 0 ? "error" : ""}
              help={errorField["id"]}
            >
              <InputNumber
                placeholder="Id único"
                style={inputStyle}
                defaultValue={gameData["id"]}
                disabled={true}
                onChange={(event) => {
                  updatedGameDate("id", event);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Nome do Jogo</span>}
              name="name"
              hasFeedback={!!errorField["name"]}
              validateStatus={errorField["name"]?.length > 0 ? "error" : ""}
              help={errorField["name"]}
            >
              <Input
                placeholder="Nome do jogo"
                style={inputStyle}
                defaultValue={gameData["name"]}
                onBlur={(event) => {
                  updatedGameDate("name", event.target.value);
                }}
                addonAfter={
                  gameImage ? (
                    <Image
                      width={44}
                      heigth={24}
                      src={gameImage}
                      alt={`Imagem do jogo: ${gameData["name"]}`}
                    />
                  ) : null
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Tipo</span>}
              name="type"
              hasFeedback={!!errorField["type"]}
              validateStatus={errorField["type"]?.length > 0 ? "error" : ""}
              help={errorField["type"]}
            >
              <AutoComplete
                placeholder="Selecione um tipo"
                mode="tags"
                tokenSeparators={[","]}
                style={inputStyle}
                options={optionsType}
                defaultValue={gameData["type"]}
                onChange={(value) => updatedGameDate("type", value)}
                filterOption={(inputValue, option) =>
                  option?.label.toLowerCase().includes(inputValue.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Habilidades</span>}
              name="main_ability_tag"
              hasFeedback={!!errorField["main_ability_tag"]}
              validateStatus={
                errorField["main_ability_tag"]?.length > 0 ? "error" : ""
              }
              help={errorField["main_ability_tag"]}
            >
              <Select
                placeholder="Habilidades estimuladas"
                mode="tags"
                tokenSeparators={[","]}
                style={inputStyle}
                options={optionsAbility}
                defaultValue={gameData["main_ability_tag"] || undefined}
                onChange={(event) => {
                  updatedGameDate("main_ability_tag", event);
                }}
              ></Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Behavior tags</span>}
              name="behavior_tags"
              hasFeedback={!!errorField["behavior_tags"]}
              validateStatus={
                errorField["behavior_tags"]?.length > 0 ? "error" : ""
              }
              help={errorField["behavior_tags"]}
            >
              <Select
                placeholder="Tags de acompanhamento"
                mode="tags"
                tokenSeparators={[","]}
                options={optionsBehaviorTags}
                style={inputStyle}
                defaultValue={gameData["behavior_tags"]}
                onChange={(event) => {
                  updatedGameDate("behavior_tags", event);
                }}
              ></Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item label={<span style={labelStyle}>Tags</span>} name="tags">
              <Select
                placeholder="Tags de filtragem"
                mode="tags"
                tokenSeparators={[","]}
                options={optionsTags}
                style={inputStyle}
                defaultValue={gameData["tags"]}
                onChange={(event) => {
                  updatedGameDate("tags", event);
                }}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<span style={labelStyle}>Visibilidade</span>}
          name="visible"
          hasFeedback={!!errorField["visible"]}
          validateStatus={errorField["visible"]?.length > 0 ? "error" : ""}
          help={errorField["visible"]}
        >
          <Radio.Group
            defaultValue={gameData["visible"] == true}
            onChange={(event) => {
              updatedGameDate("visible", event.target.value);
            }}
          >
            <Row gutter={80}>
              <Col xs={12} sm={8}>
                <Radio value={true} style={{ lineHeight: "32px" }}>
                  Visível
                </Radio>
              </Col>
              <Col xs={12} sm={8}>
                <Radio value={false} style={{ lineHeight: "32px" }}>
                  Oculto
                </Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>

        <Divider style={{ borderWidth: "4px" }} />

        <Row gutter={4}>
          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Atividades Similares</span>}
              name="similar_activities"
              hasFeedback={!!errorField["similar_activities"]}
              validateStatus={
                errorField["similar_activities"]?.length > 0 ? "error" : ""
              }
              help={errorField["similar_activities"]}
            >
              <Select
                mode="tags"
                tokenSeparators={[","]}
                placeholder="Atividades Similares"
                options={optionsSimilarActivities}
                style={inputStyle}
                defaultValue={gameData["similar_activities"]}
                onChange={(values) => {
                  updatedGameDate("similar_activities", values);
                }}
              ></Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Dificuldade</span>}
              name="difficulty"
              hasFeedback={!!errorField["difficulty"]}
              validateStatus={
                errorField["difficulty"]?.length > 0 ? "error" : ""
              }
              help={errorField["difficulty"]}
            >
              <AutoComplete
                placeholder="Dificuldades"
                style={inputStyle}
                defaultValue={gameData["difficulty"]}
                onChange={(value) => updatedGameDate("difficulty", value)}
                filterOption={(inputValue, option) =>
                  option?.label?.toLowerCase().includes(inputValue.toLowerCase()) || false
                }
                options={optionsDifficulty}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Idioma</span>}
              name="language"
              hasFeedback={!!errorField["language"]}
              validateStatus={errorField["language"]?.length > 0 ? "error" : ""}
              help={errorField["language"]}
            >
              <AutoComplete
                placeholder="Selecione um idioma"
                style={inputStyle}
                defaultValue={gameData["language"]}
                onChange={(value) => updatedGameDate("language", value)}
                filterOption={(inputValue, option) =>
                  option?.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                options={optionsLanguage}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Quantidade de níveis</span>}
              name="qtd_levels"
              hasFeedback={!!errorField["qtd_levels"]}
              validateStatus={
                errorField["qtd_levels"]?.length > 0 ? "error" : ""
              }
              help={errorField["qtd_levels"]}
            >
              <InputNumber
                placeholder="Digite uma quantidade"
                style={inputStyle}
                defaultValue={gameData["qtd_levels"]}
                onBlur={(event) =>
                  updatedGameDate("qtd_levels", event.target.value)
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Data de Lançamento</span>}
              name="release_date"
              hasFeedback={!!errorField["release_date"]}
              validateStatus={
                errorField["release_date"]?.length > 0 ? "error" : ""
              }
              help={errorField["release_date"]}
            >
              <ConfigProvider locale={ptBR}>
                <DatePicker
                  placeholder="00/00/0000"
                  format="DD/MM/YYYY"
                  style={inputStyle}
                  defaultValue={
                    gameData["release_date"]
                      ? moment(gameData["release_date"], "YYYY-MM-DD")
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      updatedGameDate(
                        "release_date",
                        date.format("YYYY-MM-DD")
                      );
                    }
                  }}
                />
              </ConfigProvider>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Tempo</span>}
              name="time"
              hasFeedback={!!errorField["time"]}
              validateStatus={errorField["time"]?.length > 0 ? "error" : ""}
              help={errorField["time"]}
            >
               <AutoComplete
                placeholder="Tempo"
                style={inputStyle}
                defaultValue={gameData["time"]}
                onChange={(value) => updatedGameDate("time", value)}
                filterOption={(inputValue, option) =>
                  option?.label?.toLowerCase().includes(inputValue.toLowerCase()) || false
                }
                options={optionsTime}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Caminho pasta</span>}
              name="path"
              hasFeedback={!!errorField["path"] || pathValid !== null}
              validateStatus={isPathInvalid ? "error" : ""}
              help={errorMessage}
            >
              <Input
                placeholder="Caminho pasta"
                style={inputStyle}
                defaultValue={gameData["path"]}
                onBlur={(event) => {
                  updatedGameDate("path", event.target.value);
                }}
                addonAfter={
                  isPathValid ? (
                    <CheckOutlined style={{ color: "green" }} />
                  ) : null
                }
              />
              {isPathValid && (
                <Row>
                  <Col span={24}>
                    <>
                      <Link
                        className={classes.TestPathLink}
                        href={GAME_API + "/" + gameData["path"] + "/index.html"}
                        target="_blank"
                      >
                        Testar caminho
                      </Link>
                      {gameImage && (
                        <div>
                          <Image
                            className={classes.ImageGame}
                            src={gameImage}
                            alt={`Imagem do jogo: ${gameData["name"]}`}
                          />
                        </div>
                      )}
                      {pdfAudio && (
                        <div>
                          <Link
                            className={classes.TestPathLink}
                            href={pdfAudio}
                            target="_blank"
                          >
                            Gabarito
                          </Link>
                        </div>
                      )}
                    </>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
          <Form.Item
              label={<span style={labelStyle}>Engine</span>}
              name="engine"
              hasFeedback={!!errorField["engine"]}
              validateStatus={errorField["engine"]?.length > 0 ? "error" : ""}
              help={errorField["engine"]}
            >
              <AutoComplete
                placeholder="Selecione um engine"
                mode="tags"
                tokenSeparators={[","]}
                style={inputStyle}
                options={optionsEngine}
                defaultValue={gameData["engine"]}
                onChange={(value) => updatedGameDate("engine", value)}
                filterOption={(inputValue, option) =>
                  option?.label.toLowerCase().includes(inputValue.toLowerCase())
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item
              label={<span style={labelStyle}>Versão</span>}
              name="version"
              hasFeedback={!!errorField["version"]}
              validateStatus={errorField["version"]?.length > 0 ? "error" : ""}
              help={errorField["version"]}
            >
              <Input
                placeholder="Versão do jogo"
                style={inputStyle}
                defaultValue={gameData["version"]}
                onBlur={(event) =>
                  updatedGameDate("version", event.target.value)
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div className={classes.buttonContainer}>
            <Button
              type="default"
              onClick={handleCancel}
              className={classes.cancelButton}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleNextPage}
              className={classes.continueButton}
            >
              Continuar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddActivitiesPage1;
