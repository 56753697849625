import React, { useEffect } from "react";
import * as routes from "./constants/routes";
import { Route, useHistory /*  Redirect,  Switch  */ } from "react-router-dom";
import { Auth } from "aws-amplify";
import Layout from "./hoc/Layout";
import "font-awesome/css/font-awesome.min.css";

import Dashboard from "./containers/Dashboard/Dashboard";
import DadosUsuarios from "./containers/UserData/DadosUsuarios";
import RegisteredCompanies from "./containers/RegisteredCompanies/RegisteredCompanies";
import AudiofocoUsers from "./containers/AudiofocoUsers/AudiofocoUsers";
import UserDataLinked from "./containers/UserDataLinked/UserDataLinked";
import UserDataDetails from "./../src/containers/UserDataDetails/UserDataDetails";
import ExcluirUsuario from "./containers/DeleteUser/DeleteUser";
import Recompensas from "./containers/Recompensas/Recompensas";
import AddRewards from "./containers/Recompensas/AddRewards/AddRewards";
import EditRewards from "./containers/Recompensas/EditReward/EditReward";
import CancelPlan from "./containers/CancelPlan/CancelPlan";
import Activities from "./containers/Activities/Activities";
import AddActivities from "./containers/Activities/AddActivities/AddActivities";
import EditActivities from "./containers/Activities/EditActivities/EditActivities";

function App() {
  const history = useHistory();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // console.log(user.attributes.email);
      })
      .catch((e) => {
        history.push("/");
        console.log(e);
      });
  });
  console.log("v1.0.0");
  // const signOut = () => {
  //   try {
  //       await Auth.signOut().then(() => { this.setState({redirect: true })});
  //   } catch (error) {
  //       console.log('error signing out: ', error);
  //   }
  // }

  return (
    <Layout>
      <Route exact path="/admin" name="dashboard" component={Dashboard} />
      <Route
        exact
        path="/admin/planos-do-usuario"
        name="dados-usuarios"
        component={DadosUsuarios}
      />
      <Route
        exact
        path="/admin/empresas-cadastradas"
        name="dados-usuarios"
        component={RegisteredCompanies}
      />
      <Route
        exact
        path="/admin/audiofoco-usuarios"
        name="dados-usuarios"
        component={AudiofocoUsers}
      />
      <Route
        exact
        path="/admin/planos-do-usuario/user/:parent_sub"
        name="dados-usuarios"
        component={UserDataLinked}
      />
      <Route
        exact
        path="/admin/planos-do-usuario/details/:email"
        name="dados-usuarios"
        component={UserDataDetails}
      />
      <Route
        exact
        path="/admin/cancelar-plano"
        name="dados-usuarios"
        component={CancelPlan}
      />
      <Route
        exact
        path="/admin/excluir-usuario"
        name="dados-usuarios"
        component={ExcluirUsuario}
      />
      <Route
        exact
        path="/admin/recompensas"
        name="recompensas"
        component={Recompensas}
      />
      <Route
        exact
        path={routes.R_ADD_REWARDS}
        name="recompensas"
        component={AddRewards}
      />
      <Route
        exact
        path="/admin/editar_recompensa/:reward_id"
        name="recompensas"
        component={EditRewards}
      />
      <Route
        exact
        path="/admin/atividades"
        name="atividades"
        component={Activities}
      />
      <Route
        exact
        path="/admin/adicionar_atividades"
        name="atividades"
        component={AddActivities}
      />
      <Route
        exact
        path="/admin/editar_atividades/:activities_id"
        name="atividades"
        component={EditActivities}
      />
    </Layout>
  );
}

export default App;
