import React, { useState, useEffect, useRef } from "react"; // Importa hooks e outras dependências
import axios from "axios"; // Para realizar requisições HTTP
import { ADMIN_REWARDS, TAG_MAP_RENDER } from "../../constants/api"; // Constantes da API
import { R_EDIT_REWARDS, R_ADD_REWARDS } from "../../constants/routes"; // Constantes de rotas
import { Auth } from "aws-amplify"; // Para autenticação com AWS
import * as classes from "./Recompensas.module.scss"; // Importação dos estilos
import Spinner from "../../Components/Spinner"; // Componente de carregamento
import { useHistory } from "react-router-dom"; // Hook para navegação de rotas
import { Table, Button, Tag, Switch } from "antd"; // Componentes da Ant Design

const Recompensas = () => {
  const [rewards, setRewards] = useState([]); // armazenar recompensas
  const [rewardsTable, setRewardsTable] = useState([]); // armazenar tabela formatada
  const [isLoadingRewards, setIsLoadingRewards] = useState(false); // controle de carregamento
  const history = useHistory(); // Hook para navegação
  const isMounted = useRef(true); // Ref para checar se o componente está montado

  const spinner = <Spinner />; // Componente de spinner (carregamento)

  // Efeito para buscar as recompensas quando o componente é montado ou recompensas mudam
  useEffect(() => {
    isMounted.current = true;

    // Se não houver recompensas, chama a função para buscar
    if (rewards.length === 0) {
      getRewards();
    }

    // Se já houver recompensas, prepara os dados para exibir na tabela
    if (rewards.length !== 0) {
      setRewardsTable(getRewardsTable(rewards));
    }

    // Limpeza da ref quando o componente for desmontado
    return () => {
      isMounted.current = false;
    };
  }, [rewards]);

  // Função para buscar recompensas da API
  const getRewards = () => {
    setIsLoadingRewards(true); // Inicia o estado de carregamento
    Auth.currentAuthenticatedUser().then((user) => { // Pega usuário autenticado
      axios
        .get(ADMIN_REWARDS, { // Faz requisição GET para pegar recompensas
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken, // Passa o token de autenticação
          },
        })
        .then((response) => {
          setIsLoadingRewards(false); 
          if (isMounted.current) { 
            console.log("recompensa", response.data)
            if (Object.keys(response.data).length > 0) {
              setRewards(response.data); // Armazena as recompensas no estado
              localStorage.setItem('rewards', JSON.stringify(response.data)); // Salva no localStorage
            } else {
              setRewards({ error: "Não foi possível trazer as recompensas" }); // Caso não haja recompensas
            }
          }
        })
        .catch((error) => {
          setIsLoadingRewards(false); // Termina o estado de carregamento
          if (isMounted.current) {
            setRewards({ error: String(error) }); // Exibe erro no estado
          }
        });
    });
  };

  // Função para obter os nomes de cabeçalho da tabela com base nas chaves
  const getHeadTableNames = (name) => {
    const names = {
      reward_name: "Nome",
      category: "Categoria",
      reward_buyed_count: "Qtd vezes comprada",
      date_created: "Criado em",
      date_updated: "Modificado em",
      money_price: "Preço (R$)",
      resource: "Recurso",
      slug: "Slug",
      star_price: "Preço (Af$)",
      type: "Tipo",
      active: "Ativo",
      deletar: "Inativar",
      editar: "Editar",
    };

    // Retorna o nome do cabeçalho ou a chave original
    const resp = name in names ? names[name] : name;
    return resp;
  };

  // Função para garantir que os dados da tabela sejam únicos
  function uniq_fast(a) {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for (var i = 0; i < len; i++) {
      var item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  // Função para formatar os dados da tabela
  const getRewardsTable = (data) => {
    if (data?.error) {
      return <div>Nenhuma recompensa disponível.</div>; // Caso haja erro ou sem dados
    }

    const columns = Object.keys(data[0]).map((key) => {
      return {
        dataIndex: key, // Define qual dado será exibido na coluna
        title: getHeadTableNames(key), // Obtém o nome do cabeçalho da tabela
        filters: uniq_fast([...data.reduce((_acc, _row) => { // Gera filtros únicos para a coluna
          if (key === 'tags') {
            if (_row[key])
              _acc.push(..._row[key].split(','));
          } else _acc.push(_row[key]);
          return _acc;
        }, [])]).map((row) => { // Mapeia os filtros
          return { text: row, value: row };
        }),
        filterSearch: true, // Permite pesquisa nos filtros
        onFilter: (value, record) => { // Função para filtrar os dados
          return String(record[key])?.includes(value);
        },
        render: (value) => { // Função para renderizar os valores
          const paternTable = {
            active: () => (
              <Switch disabled={true} defaultChecked={value === "Y"} style={{ color: "red" }}></Switch> // Exibe switch para status ativo
            ),
            tags: () => (
              <>
                {value?.split(",").map((r, i) => (
                  <Tag color={TAG_MAP_RENDER.hasOwnProperty(r) ? TAG_MAP_RENDER[r] : "geekblue"} key={'t' + i}>{r}</Tag>
                ))}
              </>
            ),
          };
          const selectPatern = paternTable.hasOwnProperty(key)
            ? paternTable[key]() // Se existir um padrão de renderização, usa
            : value;
          return selectPatern; // Retorna o valor renderizado
        },
      };
    });

    // Adiciona a coluna de edição
    columns.push({
      title: "Editar",
      dataIndex: "",
      key: "x",
      render: (record) => {
        return (
          <>
            <Button
              className={classes.EditReward} 
              onClick={() => history.push(R_EDIT_REWARDS(record.id))} // Redireciona para a página de edição
            >
              Editar
            </Button>
          </>
        );
      },
    });

    const newData = data.map((d, i) => { // Adiciona a chave 'key' para cada linha
      d["key"] = i;
      return d;
    });

    return (
      <Table
        style={{ fontSize: "14px" }}
        className={classes.RewardTable}
        columns={columns}
        dataSource={newData} 
      />
    );
  };

  return (
    <div>
      <div className={classes.RewardsList}>
        <h2 style={{ marginTop: "50px", color: "#0a4148", fontWeight: "bold" }}>RECOMPENSAS</h2>
        <Button className={classes.AddButton} onClick={() => history.push(R_ADD_REWARDS)}>
          Adicionar recompensa
        </Button>
      </div>
      {isLoadingRewards ? spinner : rewardsTable}
    </div>
  );
};

export default Recompensas; 
