import React, { useEffect, useRef, useState } from "react";
import * as classes from "./Activities.module.scss";
import { Button, Table, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ADMIN_GAMES } from "../../constants/api";
import { R_ADD_ACTIVITIES } from "../../constants/routes";
import axios from "axios";
import Spinner from "../../Components/Spinner";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import imageTrash from "../../assets/images/trash.svg";
import ModalDeleteActivities from "./ModalDeleteActivities/ModalDeleteActivities";
import ActivitiesHeader from "./AddActivities/ActivitiesHeader";
import { R_EDIT_ACTIVITIES } from "../../constants/routes";

const Activities = () => {
  const [games, setGames] = useState([]);
  const [isLoadingGames, setIsLoadingGames] = useState(false);
  const isMounted = useRef(true);
  const history = useHistory();
  const spinner = <Spinner />;

  const [loading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  useEffect(() => {
    isMounted.current = true;
    getGames();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const showModal = (game) => {
    setSelectedGame(game);
    setOpen(true);
  };

  const handleOk = () => {
    setIsLoading(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const getGames = () => {
    setIsLoadingGames(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return axios.get(ADMIN_GAMES, {
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken, 
          },
        });
      })
      .then((response) => {
        setIsLoadingGames(false);

        if (isMounted.current) {
          console.log("Resposta da API:", response.data);

          const data = response.data;

          const updatedData = data.map((game) => {
            // if (game.type === "Audio") {
            //   game.type = "Audio";
            // }
            return game;
          });

          if (Object.keys(updatedData).length > 0) {
            setGames(updatedData); 
            localStorage.setItem("games", JSON.stringify(updatedData));
            
          } else {
            setGames([{ error: "Não foi possível trazer os jogos" }]);
          }
        }
      })
      .catch((error) => {
        setIsLoadingGames(false);
        console.error("Erro ao carregar jogos:", error);
        if (isMounted.current) {
          setGames([]);
        }
      });
  };

  // Função para mapear os nomes das colunas da tabela
  const getHeadTableNames = (name) => {
    const names = {
      id: "Id",
      name: "Nome",
      type: "Tipo",
      language: "Idioma",
      release_date: "Lançamento",
      behavior_tags: "Behavior Tags",
      visible: "Visibilidade",
    };
    return names[name] || name;
  };

  // Lista de colunas permitidas
  const selectedColumns = [
    "id",
    "name",
    "type",
    "language",
    "release_date",
    "behavior_tags",
    "visible",
  ];

  // Função para garantir que os dados da tabela sejam únicos
  function uniq_fast(a) {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for (var i = 0; i < len; i++) {
      var item = a[i];
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  //Estilo para os tipos de jogos
  const typeStyles = {
    Game: {
      color: "#08979C",
      background: "#E6FFFB",
      border: "1px solid #87E8DE",
    },
    Audio: {
      color: "#084C9C",
      background: "#E6F3FF",
      border: "1px solid #87B9E8",
    },
    default: {
      color: "#000",
      background: "#f0f0f0",
      border: "1px solid #ccc",
    },
  };

  const visibleStyles = {
    false: {
      text: "Oculto",
      color: "#D80404",
    },
    true: {
      text: "Visível",
      color: "#06A820",
    },
  };

  // Configuração dinâmica das colunas da tabela
  const columns = selectedColumns.map((key) => ({
    dataIndex: key,
    title: getHeadTableNames(key),
    key,
    render: (value) => {
      if (key === "release_date") {
        return value ? new Date(value).toLocaleDateString("pt-BR") : "/";
      }
      if (key === "type") {
        const { color, background, border } =
          typeStyles[value] || typeStyles.default;
        return (
          <span
            style={{
              backgroundColor: background,
              color: color,
              border: border,
              borderRadius: "4px",
              padding: "4px 8px",
            }}
          >
            {value}
          </span>
        );
      }
      if (key === "visible") {
        const { text, color } = visibleStyles[value || "false"];
        return (
          <span style={{ color: color, fontSize: "13px", fontWeight: "400" }}>
            {text}
          </span>
        );
      }
      return Array.isArray(value) ? value.join(", ") : value?.toString() || "-";
    },

    // Gerando filtros únicos para cada coluna
    filters: uniq_fast([
      ...games.reduce((_acc, _row) => {
        if (key === "tags") {
          if (_row[key]) _acc.push(..._row[key].split(","));
        } else {
          _acc.push(_row[key]);
        }
        return _acc;
      }, []),
    ]).map((row) => ({
      text: row,
      value: row,
      style: typeStyles[row] || typeStyles.default,
    })),

    // Permite a busca dentro dos filtros
    filterSearch: true,

    onFilter: (value, record) => {
      return String(record[key])?.includes(value);
    },
  }));

  // Coluna para o botão de deletar
  columns.push({
    title: "Editar",
    dataIndex: "",
    key: "actions",
    render: (_, record) => {
      return (
        <Space>
          <Button
            className={classes.EditActivities}
            onClick={() => history.push({
              pathname: R_EDIT_ACTIVITIES(record.id),
              state: { game: record } 
            })} 
          >
            Editar
          </Button>
          <Button
            className={classes.DeleteButton}
            onClick={() => showModal(record)}
            danger
          >
            <img src={imageTrash} alt="Ícone de lixeira" />
          </Button>
        </Space>
      );
    },
  });

  return (
    <div>
      <div className={classes.ActivitiesList}>
        <ActivitiesHeader text="Atividades Afinando" />
        <Button
          className={classes.AddButton}
          onClick={() => history.push(R_ADD_ACTIVITIES)}
        >
          <PlusOutlined />
          Nova Atividade
        </Button>
      </div>

      {isLoadingGames ? (
        <Spinner />
      ) : (
        <Table
          className={classes.ActivitiesTable}
          columns={columns}
          dataSource={games.map((game, i) => ({ ...game, key: i }))}
          scroll={{ x: "auto" }}
        />
      )}
      <ModalDeleteActivities
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loading={loading}
        game={selectedGame}
      />
    </div>
  );
};

export default Activities;
