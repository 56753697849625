import React from "react";

const ActivitiesHeader = ({ text }) => {
  return (
    <h2 style={{ marginTop: "40px", color: "#0a4148", fontWeight: "bold", fontSize: "24px", marginBottom: "25px", textTransform: "uppercase" }}>
      {text}
    </h2>
  );
};

export default ActivitiesHeader;
