import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AddActivities from "../AddActivities/AddActivities";
import { Auth } from "aws-amplify";
import { R_ACTIVITIES } from "../../../constants/routes";
import { ADMIN_GAMES } from "../../../constants/api";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EditActivities = () => {
  const location = useLocation();
  const { game: initialGame } = location.state || {};
  const history = useHistory();
  const [game, setGame] = useState(initialGame || {});

  useEffect(() => {
    if (initialGame) {
      setGame(initialGame);
    }
  }, [initialGame]);

  const saveGame = (gameData) => {
    console.log("gamedata",gameData);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return axios.put(
          ADMIN_GAMES,
          { game: gameData },
          {
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then((response) => {
        console.log(response);
        console.log(response.status);
        if (response.status === 200) {
          console.log("status 200");
          console.log(R_ACTIVITIES);
          history.push(R_ACTIVITIES);
        }
      });
  };
  return <AddActivities saveGame={saveGame} game={game} />;
};

export default EditActivities;
