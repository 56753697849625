import React, { useEffect, useState } from "react";
import ActivitiesHeader from "./ActivitiesHeader";
import { Form, Button, Divider, Input, Row, Col, Select } from "antd";
import classes from "./AddActivities.module.scss";

const AddActivitiesPage2 = ({
  backPage,
  finalizeRegister,
  gameData,
  updatedGameDate,
  gamesMapField,
}) => {
  const [optionElaboration, setOptionElaboration] = useState([]);
  const [optionDevelopment, setOptionDevelopment] = useState([]);
  const [optionIllustration, setOptionIllustration] = useState([]);
  const [infos, setInfos] = useState({});
  const [content, setContent] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (gamesMapField) {
      setOptionElaboration(
        gamesMapField["credits"]?.elaboration?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionDevelopment(
        gamesMapField["credits"]?.development?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
      setOptionIllustration(
        gamesMapField["credits"]?.illustration?.map((ability) => ({
          label: ability,
          value: ability,
        }))
      );
    }
  }, [gamesMapField]);

  useEffect(() => {
    const newInfos = {};
    if (gameData.infos && gameData.infos[0] && gameData.infos[0].body) {
      newInfos["description"] = gameData.infos[0];
    }

    if (gameData.infos && gameData.infos[1] && gameData.infos[1].body)
      newInfos["benefits"] = gameData.infos[1];
    if (gameData.infos && gameData.infos[2] && gameData.infos[2].body)
      newInfos["skills"] = gameData.infos[2];
    setInfos(newInfos);
  }, [gameData]);

  useEffect(() => {
    if (infos) {
      updatedContent();
    }
  }, [infos]);

  const handleCancel = () => {
    backPage();
  };

  const handleFinalizeRegistration = () => {
    console.log("Finalizando o cadastro...");
    setButtonDisable(true);

    console.log(
      "Chamada para desabilitar o botão. Valor de buttonDisable:",
      true
    );
    finalizeRegister();
  };

  useEffect(() => {
    updatedContent();
    console.log("O botão foi desabilitado: ", buttonDisable);
  }, [buttonDisable]);

  const labelStyle = {
    color: "#3C3C3C",
    fontSize: "16px",
    fontWeight: "500",
  };

  const inputStyle = {
    width: "100%",
    height: "120px",
  };

  console.log("ilustração", gameData["credits"]?.illustration);

  const updatedContent = () => {
    setContent(
      <>
        <ActivitiesHeader text="Cadastrar Atividade" />
        <Form
          size="large"
          layout="vertical"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 16 }}
        >
          <h3
            style={{
              color: "#0A4148",
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Informações adicionais{" "}
          </h3>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                label={<span style={labelStyle}>Habilidades estimuladas</span>}
              >
                <Input.TextArea
                  placeholder="Adicione uma descrição"
                  style={inputStyle}
                  defaultValue={gameData.infos[0].body}
                  onChange={(event) => {
                    updatedGameDate(["infos", 0, "body"], event.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={labelStyle}>
                    Quem se beneficia com este jogo?
                  </span>
                }
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Adicione uma descrição"
                  style={inputStyle}
                  defaultValue={gameData.infos[1].body}
                  onChange={(event) => {
                    updatedGameDate(["infos", 1, "body"], event.target.value);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span style={labelStyle}>O que você pode melhorar?</span>
                }
                name="title"
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Adicione uma descrição"
                  style={inputStyle}
                  defaultValue={gameData.infos[2].body}
                  onChange={(event) => {
                    updatedGameDate(["infos", 2, "body"], event.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ borderWidth: "4px" }} />
          <h3
            style={{
              color: "#0A4148",
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Créditos
          </h3>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <Form.Item
                label={<span style={labelStyle}>Elaboração</span>}
                name="elaboration"
              >
                <Select
                  placeholder="Selecionar"
                  mode="tags"
                  tokenSeparators={[","]}
                  style={inputStyle}
                  options={optionElaboration}
                  defaultValue={gameData["credits"]?.elaboration || []}
                  onChange={(event) => {
                    updatedGameDate("credits", {
                      ...gameData["credits"],
                      elaboration: event,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label={<span style={labelStyle}>Desenvolvimento</span>}
                name="development"
              >
                <Select
                  placeholder="Selecionar"
                  mode="tags"
                  tokenSeparators={[","]}
                  style={inputStyle}
                  options={optionDevelopment}
                  defaultValue={gameData["credits"]?.development || []}
                  onChange={(event) => {
                    updatedGameDate("credits", {
                      ...gameData["credits"],
                      development: event,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label={<span style={labelStyle}>Ilustração</span>}
                name="illustration"
              >
                <Select
                  placeholder="Selecionar"
                  mode="tags"
                  tokenSeparators={[","]}
                  style={inputStyle}
                  options={optionIllustration}
                  defaultValue={gameData["credits"]?.illustration || []}
                  onChange={(event) => {
                    updatedGameDate("credits", {
                      ...gameData["credits"],
                      illustration: event,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                onClick={handleCancel}
                className={classes.cancelButton}
              >
                Voltar
              </Button>
              <Button
                type="primary"
                danger
                onClick={handleFinalizeRegistration}
                disabled={buttonDisable}
                className={classes.continueButton}
              >
                Finalizar cadastro
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    );
  };

  return <>{content}</>;
};

export default AddActivitiesPage2;
